<template>
  <div ref="container" class="mapView overflow-x-auto overflow-y-hidden "  v-bind:class="{'map-mobile-view':screenType.isMobileScreen}" :style="{width:screenType.isMobileScreen ? `${screenType.mainLayoutWidth *2 -30}px`:'100%',marginLeft:screenType.isMobileScreen ?`-${screenType.mainLayoutWidth/2}px`:0,marginTop:screenType.isMobileScreen ?'-150px':0}">
    <canvas ref="myCanvas"></canvas>
    <div class="bg"></div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="item"
      v-for="item in jettyPositionStyle"
      :style="item.cardPositon"
      @click="handleItemClick(item)"
    >
      <div v-if="jettyData[item.id]?.[0] || jettyData[item.id]?.[1]">
        <div>
          <span class="dot"></span>
          <span class="time">{{getDisplayTime(showTaget(jettyData[item.id])["berthAssignTiming"])}}</span>
        </div>
        <!-- <span class="name">{{
          showTaget(jettyData[item.id])["companyName"]
        }}</span>
        <br />
        <span class="name">Loading Completed</span> -->

        <div class="name placeholder">{{showTaget(jettyData[item.id])["companyName"]}}</div>
        <div class="name placeholder">{{showTaget(jettyData[item.id])["vesselName"]}}</div>
        <div class="name">{{showTaget(jettyData[item.id])["status"]}}</div>
      </div>
      <div class="notData" v-else>Not Assigned</div>
    </div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="number"
      v-for="item in jettyPositionStyle"
      :style="{
        ...item.numerPosition,
        background:
          jettyData[item.id]?.[0] || jettyData[item.id]?.[1]
            ? '#008CDB'
            : '#B8BCCA',
      }"
    >
      {{ item.num }}
    </div>
    <!-- eslint-disable-next-line vue/require-v-for-key -->
    <div
      class="line"
      v-for="item in jettyPositionStyle"
      :style="item.linePosition"
    ></div>
    <img
      class="ship"
      v-for="item in jettyPositionStyle"
      :style="item.shipPosition"
      :src="require('@/assets/map-view-ship.png')"
      alt="Ship Icon"
      v-if="jettyData[item.id]?.[0] || jettyData[item.id]?.[1]"
    />
  </div>
</template>
<script>
import mapViewSrc from "@/assets/map-view-preview.png";
import mapLineBg from "@/assets/map-line.png";
import mapYellowBg from "@/assets/map-yellow-bg.png";
import mapViewBg from "@/assets/map-view-bg.png";
import { mapState } from "vuex";
export default {
  name: "mapView",
  data() {
    return {
      mapViewSrc,
      ctx: null,
      width: null,
      imageStationHeight: 257,
      height: 600,
      jettyPositionStyle: {
        "Berth 3": {
          cardPositon: {
            left: "1085px",
            top: "280px",
          },
          numerPosition: {
            top: "380px",
            left: "1140px",
          },
          linePosition: {
            width: "22px",
            left: "1160px",
            top: "380px",
            transform: "rotate(-90deg)",
          },
          shipPosition: {
            left: "1120px",
            top: "435px",
            transform: "rotate(180deg)",
          },
          id: "3",
          num: "03",
        },
        "Berth 5": {
          cardPositon: {
            top: "500px",
            left: "350px",
          },
          numerPosition: {
            top: "435px",
            left: "445px",
          },
          linePosition: {
            width: "26px",
            left: "465px",
            top: "500px",
            transform: "rotate(-90deg)",
          },
          shipPosition: {
            left: "430px",
            top: "394px",
            transform: "rotate(180deg)",
          },
          id: "5",
          num: "05",
        },
        "Berth 6": {
          cardPositon: {
            top: "480px",
            left: "540px",
          },
          numerPosition: {
            top: "421px",
            left: "594px",
          },
          linePosition: {
            left: "615px",
            top: "460px",
            width: "27.92px",
            transform: "rotate(90deg)",
          },
          shipPosition: {
            left: "570px",
            top: "388px",
            transform: "rotate(180deg)",
          },
          id: "6",
          num: "06",
        },
        "Berth 7": {
          cardPositon: {
            top: "480px",
            left: "716px",
          },
          numerPosition: {
            left: "721px",
            top: "416px",
          },
          linePosition: {
            width: "29px",
            left: "743px",
            top: "455px",
            transform: "rotate(90deg)",
          },
          shipPosition: {
            left: "706px",
            top: "385px",
            transform: "rotate(180deg)",
          },
          id: "7",
          num: "07",
        },
        "Berth 8": {
          cardPositon: {
            left: "400px",
            top: "34px",
          },
          numerPosition: {
            left: "463px",
            top: "128px",
          },
          linePosition: {
            width: "50px",
            height: "2px",
            left: "485px",
            top: " 80px",
            transform: "rotate(90deg)",
          },
          shipPosition: {
            left: "450px",
            top: "190px",
          },
          id: "8",
          num: "08",
        },
        "Berth 9": {
          cardPositon: {
            left: "560px",
            top: "34px",
          },
          numerPosition: {
            left: "600px",
            top: "128px",
          },
          linePosition: {
            width: "53px",
            height: "2px",
            left: "640px",
            top: "80px",
            transform: "rotate(110deg)",
          },
          shipPosition: {
            left: "580px",
            top: "190px",
          },
          id: "9",
          num: "09",
        },
        "Berth 10": {
          cardPositon: {
            left: "720px",
            top: "34px",
          },
          numerPosition: {
            left: "730px",
            top: "128px",
          },
          linePosition: {
            width: "54px",
            left: "795px",
            top: "90px",
            transform: "rotate(130deg)",
          },
          shipPosition: {
            left: "710px",
            top: "190px",
          },
          id: "10",
          num: "10",
        },
        "Berth 11": {
          cardPositon: {
            left: "1185px",
            top: "180px",
          },
          numerPosition: {
            left: "1290px",
            top: "290px",
          },
          linePosition: {
            width: "62px",
            left: "1297px",
            top: "296px",
            transform: " rotate(224deg)",
          },
          shipPosition: {
            left: "1301px",
            top: "320px",
            transform: "rotate(120deg)",
          },
          id: "11",
          num: "11",
        },
        "Berth 13": {
          cardPositon: {
            left: "1260px",
            top: "25px",
          },
          numerPosition: {
            left: "1407px",
            top: "108px",
          },
          linePosition: {
            width: "58px",
            left: "1408px",
            top: "122px",
            transform: "rotate(220deg)",
          },
          shipPosition: {
            left: "1412px",
            top: "140px",
            transform: "rotate(120deg)",
          },
          id: "13",
          num: "13",
        },
        "Berth 15": {
          cardPositon: {
            left: "240px",
            top: "34px",
          },
          numerPosition: {
            left: "325px",
            top: " 128px",
          },
          linePosition: {
            width: "56px",
            height: "2px",
            left: "311px",
            top: "83px",
            transform: "rotate(55.63deg)",
          },
          shipPosition: {
            left: "320px",
            top: "190px",
          },
          id: "15",
          num: "15",
        },
        "Berth 16": {
          cardPositon: {
            left: "80px",
            top: "34px",
          },
          numerPosition: {
            left: "226px",
            top: " 128px",
          },
          linePosition: {
            width: "89px",
            height: "2px",
            left: "157px",
            top: "85px",
            transform: "rotate(33.13deg)",
          },
          shipPosition: {
            top: "190px",
            left: "197px",
          },
          id: "16",
          num: "16",
        },
        "Berth 17": {
          cardPositon: {
            left: "12px",
            top: "138px",
          },
          numerPosition: {
            left: "110px",
            top: "229px",
          },
          linePosition: {
            width: "43px",
            left: "82px",
            top: "210px",
            transform: "rotate(35deg)",
          },
          shipPosition: {
            top: "250px",
            left: "145px",
            transform: "rotate(294deg)",
          },
          id: "17",
          num: "17",
        },
      },
    };
  },
  props: {
    jettyData: {
      type: Object,
      default() {
        return {
          "3": [],
          "5": [],
          "6": [],
          "7": [],
          "8": [],
          "9": [],
          "10": [],
          "11": [],
          "13": [],
          "15": [],
          "16": [],
          "17": [],
        };
      },
    },
  },
  created() {
  },
  methods: {
    getDisplayTime(val){
      if (!val || val==='') return '';
     var index =  val.lastIndexOf(':')
      return val.substring(0,index)
    },
    showTaget(item) {
      if (item[0] && !item?.[1]) {
        return item[0];
      }
      if (item[1] && !item?.[0]) {
        return item[1];
      }
      if (item[0] && item[1]) {
        return item[0];
      }
    },
    initCanvas() {
      let container = this.$refs.container;
      let width = container.clientWidth;
      this.width = width;
      // this.width = '1540';
      this.ctx = this.$refs.myCanvas.getContext("2d");
      this.ctx.canvas.width = width;
      this.ctx.canvas.height = this.height;
      this.ctx.clearRect(0, 0, this.width, this.height);
      // this.drawBg();
      // this.drawLine();
    },
    async drawBg() {
      let imageStation = await this.addImageProcess(
        mapViewBg,
        63,24
        // this.width * 0.83,
        // this.imageStationHeight
      );
      this.ctx.drawImage(imageStation, 0, 0);
      let imageLine = await this.addImageProcess(
        mapLineBg,
        this.width * 0.83,
        this.imageStationHeight
      );
      this.ctx.drawImage(imageLine, 0, 0);
    },
    drawLine() {
      this.ctx.lineWidth = 5;
      let ctx = this.ctx;
      ctx.strokeStyle = "white";
      ctx.beginPath();
      ctx.moveTo(820, 240);
      ctx.lineTo(840, 445);
      ctx.stroke();
      ctx.moveTo(380, 455);
      ctx.lineTo(1000, 440);
      ctx.stroke();
      ctx.moveTo(1150, 130);
      ctx.lineTo(1250, 200);
      ctx.stroke();
      ctx.moveTo(1342, 70);
      ctx.lineTo(1122, 370);
      ctx.stroke();
      ctx.closePath();
    },
    drawJettyDot() {},
    addImageProcess(src, width, height) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.width = width;
        img.height = height;
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    },
    handleItemClick(item){
      let target = this.jettyData[item.id]?.[0] || this.jettyData[item.id]?.[1];
      if(target){
        this.$router.push({
        path: "/barging/index",
        query: {
          id: target.bId,
          pageStatus: 1,
        },
      });
      }
    }
  },
  mounted() {
    this.initCanvas();
    console.log(JSON.stringify(this.jettyData))

  },
  computed: {
    ...mapState(["screenType"]),
 
  },
};
</script>
<style lang="scss" scoped>
.mapView {
  background-color: #0a1129;
  // width: 100%;
  position: relative;
  height: 600px;

  canvas {
    position: absolute;
    z-index: 2;
  }
  .bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    min-width: 1560px;
    height: 600px;
    background: linear-gradient(
      309.88deg,
      #177b9d 8.29%,
      rgba(145, 226, 255, 0.52) 103.93%
    );
    box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.1);

    background-image: url('../../assets/map-view-bg.png');
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.item {
  height: 85px;
  width: 150px;
  border-radius: 2px;
  position: absolute;
  padding: 3px 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 2px rgba(196, 196, 196, 0.4);
  z-index: 4;
  align-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .time {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    padding-left: 5px;
    color: #4c565c;
  }
  .dot {
    display: inline-block;
    width: 11px;
    height: 11px;
    left: 432px;
    border-radius: 50%;
    background: #008CDB;
  }
  .name {
    font-size: 12px;
    line-height: 14px;
    color: #4c565c;
    align-content: center;

    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .name:hover {
  cursor: pointer; /* Optional: Indicate interactivity */
}
  .placeholder {
    height: 20px;
  }
}
.number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40.25px;
  height: 40.25px;

  border-radius: 50%;
  background: #2dc12a;
  color: white;
  border: 2px solid #ffffff;
  z-index: 3;
}
.line {
  position: absolute;
  transform-origin: 0 0;
  z-index: 3;
  height: 2px;
  background: white;
}
.ship {
  position: absolute;
  z-index: 3;
  height: auto;
  width: 80px;
}
.notData {
  font-size: 12px;
  text-align: center;
  line-height: 48px;
  padding: 0;
  color: #4c565c;
  // width: 130px;
  align-content: center;
}

.map-mobile-view {
  // width:930px;
  scale: 50%;
  // position: relative;
  // height: 600px;
  // margin-left:-240px ;
  
  canvas {
    // position: absolute;
    // z-index: 2;
  }
  .bg {
    // position: absolute;
    // z-index: 1;
    // width: 100%;
    // min-width: 1540px;
    // height: 600px;
  }
}
</style>