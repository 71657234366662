<template>
  <el-dialog
    class="successDialog"
    :visible.sync="show"
    :width="isMobileScreen?'85%':'420px'"
    :show-close="false"
    :close-on-click-modal="shipVettingId ? false : true"
    :close-on-press-escape="shipVettingId ? false : true"
  >
    <div class="flex flex-col items-center">
      <svg-icon iconClass="success" />
      <p class="message">{{ message }}</p>
    </div>
    <div class="flex justify-center mt-5">
      <el-button size="small" type="primary" @click="close">{{
        $t("btn.close")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import SvgIcon from "../SvgIcon.vue";
import { mapState } from "vuex";
export default {
  components: { SvgIcon },
  data() {
    return {
      message: "Document has been signed",
      show: false,
      userTypes: "",
      userAdd: "",
      shipVettingId: null,
    };
  },
  created() {
    this.userTypes = this.$store.state.userInfo.userType;
  },
  computed: {
    ...mapState(["screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    },
  },
  methods: {
    open(val) {
      this.show = true;
    },
    close() {
      this.show = false;
      if(this.shipVettingId){
        this.$router.replace({
          path: "/ship-vetting/index",
          query: {
            id: this.shipVettingId,
            pageStatus: 1,
          },
        });
        sessionStorage.setItem("pageStatus", 1);
        this.$router.go(0);
        return;
      }
      if (this.userAdd == 1) {
        this.userAdd = "";
        this.$router.push({
          url: "barging/index",
          query: {
            pageStatus: 0,
          },
        });
      } else {
        this.$emit("upup", this.userTypes);
      }
    },
  },
};
</script>

<style lang="scss">
.successDialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .svg-icon {
    width: 140px;
    height: 100px;
  }
  .message {
    font-size: 18px;
    text-align: center;
    color: #82889c;
    margin-top: 10px;
  }
}
</style>
