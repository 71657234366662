<template>
  <div class="ship-detail" style="padding:20px 20px; margin-top:0px; background-color:#ffffff; border-radius: 10px;">
    <div class="my-3 flex flex-wrap justify-between gap-2">
      <span v-if="pageStatus == 1">{{ requestData.shipName }}</span>
      <span v-else>Create a New Ship</span>
      <div>
        <el-button size="mini" @click="handleCloseClick">Close</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(1)" @click="handleStatusButtonClick(0)">Submit To Terminal Approval</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(2)" @click="confirmToSubmitDialog = true">Submit</el-button>
        <el-button size="mini" type="success" v-if="showButtons(3)" @click="handleStatusButtonClick(6)">Accept</el-button>
        <el-button size="mini" type="danger"  v-if="showButtons(4)" @click="handleStatusButtonClick(5)">Reject</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(5)" @click="handleStatusButtonClick(2)">Submit for Inspection</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(6)" @click="handleStatusButtonClick(4)">Anchorage Inspection</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(7)" @click="logisticsRemarksDialog = true">More Information Required</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(8)">Pending Inspector</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(9)">Anchorage Inspection</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(10)">Pending Observation</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(11)">More Information Required</el-button>
        <el-button size="mini" type="primary" v-if="showButtons(12)" @click="handleStatusButtonClick(1)">Resubmit Changes</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(13)">Inspector Responded</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(14)">Accepted</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(15)">Rejected</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(16)">Expired</el-button>
        <el-button size="mini" type="text" disabled v-if="showButtons(17)">Pending Logistics</el-button>
      </div>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Ship Information" name="informationTab">
      </el-tab-pane>
      <el-tab-pane label="History Record" name="historyTab" v-if="pageStatus == 1">
      </el-tab-pane>
    </el-tabs>
    <ShipVettingHistory 
      v-show="activeTab == 'historyTab'" 
      :imo="imo"
      :key="imo + historyKey"
    />
    <ShipVettingInformation 
      v-show="activeTab == 'informationTab'" 
      ref="shipVettingForm" 
      :requestData="requestData"
      @get-list="getShipVettingReqeustDetail"
    />

    <el-dialog title="Confirm To Submit" :visible.sync="confirmToSubmitDialog" :width="isMobileScreen ? '100%' : '500px'">
      <div>
        Once submitted, the request cannot be modified.
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="confirmToSubmitDialog = false">Cancel</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(1)">Submit</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog title="More Information Required" :visible.sync="logisticsRemarksDialog" :width="isMobileScreen ? '100%' : '500px'" @click="resetLogisticsRemarksForm()">
      <div>
        <el-form label-width="auto" size="mini" label-position="top" ref="remarksForm">
          <el-form-item label="Please state the remark:" prop="logisticsRemarks" >
            <el-input type="textarea" v-model="logisticsRemarks" clearable size="small" :style="{ width: '100%' }" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="resetLogisticsRemarksForm()">{{ $t("btn.close") }}</el-button>
          <el-button type="primary" size="mini" @click="handleStatusButtonClick(8)">{{ $t("btn.submit") }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div> 
</template>
  
<script>
import tabs from "@/components/tabs.vue";
import { getCoqMangeListList, getShipVettingRequestData } from "@/services";
import { getQuantityNum } from "@/services/user";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
import { PDFDocument } from 'pdf-lib'
import ShipVettingHistory from "./history.vue";
import ShipVettingInformation from "./information.vue"
export default {
  name: "ShipVettingDetail",
  components: { tabs, PdfView, ShipVettingInformation, ShipVettingHistory },

  data() {
    return {
      activeTab: "informationTab",
      // activeTab: "historyTab",
      historyKey: 0,
      id:"",
      pageStatus: "", // page mode
      requestStatus: 0,
      // upIndex: 0,
      imo: "",
      shipName: "",
      requestData: {},
      confirmToSubmitDialog: false,
      logisticsRemarksDialog: false,
      logisticsRemarks: "",
      // subbtns: false,
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
    this.getShipVettingReqeustDetail();
  },
  mounted() {
    const { id, pageStatus } = this.$route.query;
    this.id = id;
    this.pageStatus = pageStatus;
    // this.getShipVettingReqeustDetail();
  },
  methods: {
    getShipVettingReqeustDetail(){
      this.logisticsRemarks = "";
      if (this.$route.query.pageStatus == 2){
        return;
      }
      getShipVettingRequestData(this.$route.query.id).then((res) => {
        if(res.code == 200){
          this.requestData = res.data
          this.imo = res.data.imo
          this.historyKey += 1; // Increment the key to force re-render
        }
      });
      
    },
    showButtons(val) {
      const requestStatus = this.requestData.shipStatus;
      const pageStatus = this.$route.query.pageStatus;
      const userRole = this.userInfo.role;
      const isLogistics = this.userInfo.role == 1;
      const isCustomer = this.userInfo.role == 4 || this.userInfo.role == 5;
      const isInspector = this.userInfo.role == 13;

      if (val == 1) {
        // Submit To Terminal Approval
        return pageStatus == 2 && isCustomer;
      }
      if (val == 2) {
        // Submit
        const pendingInspector = requestStatus == 2 && isInspector;
        const anchorage = requestStatus == 4 && isInspector;
        const pendingObservation = requestStatus == 7 && isCustomer;
        const moreInfoRequire = requestStatus == 8 && isCustomer;
        return pageStatus == 1 && (pendingInspector || anchorage || pendingObservation || moreInfoRequire);
      }
      if (val == 3 || val == 4) {
        // Accept, Reject
        return isLogistics && (pageStatus == 2 || requestStatus == 1 || requestStatus == 3);
      }
      if (val == 5) {
        // Submit for Inspection
        return isLogistics && (pageStatus == 2 || requestStatus == 1);
      }
      if (val == 6) {
        // Anchorage Inspection
        return isLogistics && (pageStatus == 2 || requestStatus == 1 || requestStatus == 3);
      }
      if (val == 7) {
        // More Information Required
        return isLogistics && (pageStatus == 2 || requestStatus == 1 || requestStatus == 3);
      }
      if (val == 8) {
        // Pending Inspector
        return !isInspector && requestStatus == 2;
      }
      if (val == 9) {
        // Anchorage Inspection
        return !isInspector && requestStatus == 4;
      }
      if (val == 10) {
        // Pending Observation
        return !isCustomer && requestStatus == 7;
      }
      if (val == 11) {
        // More Information Required
        return !isCustomer && requestStatus == 8;
      }
      if (val == 12){
        // resubmit changes
        return isCustomer && requestStatus == 1;
      }
      if (val == 13){
        // inspector responded
        return !isLogistics && requestStatus == 3;
      }
      if (val == 14){
        // accepted
        return requestStatus == 6;
      }
      if (val == 15){
        // accepted
        return requestStatus == 5;
      }
      if (val == 16){
        // expired
        return requestStatus == 9;
      }
      if (val == 17){
        // pending logistics word
        return isInspector && requestStatus == 1;
      }
    },
    handleCloseClick() {
      this.pageStatus = 0;
      if(this.$route.query.pageStatus == 2){
        this.requestData = {};
      }
      this.$router.back();
      // fix back error
      setTimeout(() => {
        if (this.$route.path == "/ship-vetting/index" && this.$route.query.id) {
          this.$router.push({ path: "/ship-vetting/index" });
        }
      }, 500);
    },
    handleStatusButtonClick(status){
      // More Info Remark mandatory
      if(status == 8 && (this.logisticsRemarks == null || this.logisticsRemarks.trim() == "")){
        this.$message.error("Remark required");
        return;
      }
      this.confirmToSubmitDialog = false;
      this.logisticsRemarksDialog = false;
      
      this.$refs.shipVettingForm.updateRequest(status,this.logisticsRemarks,() => {
        // this.subbtns = true;
      });
      this.logisticsRemarks = "";
    },
    resetLogisticsRemarksForm(){
      // this.$refs.logiscticsRemarkForm.clearValidate();
      this.logisticsRemarksDialog= false;
      this.logisticsRemarks = "";
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
.ship-detail{
  height: 100%
  // height: calc(100vh - 90px);
  // overflow: hidden;
}

</style>
