<template>
  <div :class="['timeLine', timelineloading ? 'loading' : '']">
    <div class="head flex justify-between">
      <div>
        {{ $t("common.timeline") }}
        <span class="pl-1" style="font-size:10px">({{ activities.length }}/{{ total }})</span>
      </div>
      <div><i v-if="loading" class="el-icon-refresh rotating"></i></div>
    </div>
    
    <div class="content" @scroll="onScroll">
      <el-timeline :reverse="false" v-loading="timelineloading">
        <el-timeline-item v-for="(item, index) in activities" :key="index" v-bind:class="{lastItem: index==activities.length-1}">
          <div>
            <span :class="item.model == 5 ? 'text' : ''">
            Ship Vetting
            </span>
            <span>
              <!-- {{
                item.logType == 0
                    ? ""
                    : item.logType == 1
                        ? "Pending Logistics"
                        : item.logType == 2
                            ? "Pending Inspector"
                            : item.logType == 3
                                ? "Inspector Responded"
                                : item.logType == 4
                                    ? "Anchorage Inspection"
                                    : item.logType == 5
                                        ? "Rejected"
                                        : item.logType == 6
                                            ? "Accepted"
                                            : item.logType == 7
                                                ? "Pending Observation"
                                                : item.logType == 8
                                                    ? "More Information Required"
                                                    : item.logType == 9
                                                        ? "Customer Uploaded Document"
                                                        : ""
              }} -->
              {{ getShipVettingLogType(item.logType) }}
            </span>
            <p class="timestamp">
              <span
                >{{ item.createTime ? item.createTime : "" }}
                <span style="color: #a1a6b5" v-show="item.createBy"> by</span>
                {{ item.createBy }}
              </span>
            </p>
          </div>
          <el-divider v-show="item.subs"></el-divider>
          <!-- <div v-if="item.model == 5">
            <span v-for="(i, index) in item.subs" :key="index">
              <p class="actionDev text-xs" v-show="i.fname">
                <span style="color: #a1a6b5"> {{ i.fname }}</span> :
                <span v-if="i.oldValue && i.oldValue.length > 0"> Changed from </span>
                <span v-if="i.oldValue && i.oldValue.length > 0"
                      class="primayColor">{{ i.oldValue ? i.oldValue : "Empty" }}</span>
                <span v-if="i.oldValue && i.oldValue.length > 0"> to </span>
                <span class="primayColor">{{ i.targetValue }}</span>
              </p>
            </span>
          </div> -->
          <div v-if="item.logType">
            <span v-for="(i, index) in item.subs" :key="index">
              <p class="actionDev text-xs" v-show="i.fname">
                <span style="color: #a1a6b5"> {{ i.fname }}</span> :
                <span v-if="i.oldValue && i.oldValue.length > 0"> Changed from </span>

                <span v-if="i.fname === 'shipStatus' && i.oldValue && i.oldValue.length > 0" class="primayColor">{{ getShipVettingStatus(i.oldValue) }}</span>
                <span v-else-if="i.oldValue && i.oldValue.length > 0" class="primayColor">{{ i.oldValue ? i.oldValue : "Empty" }}</span>

                <span v-if="i.oldValue && i.oldValue.length > 0"> to </span>
                <span v-if="i.fname === 'shipStatus'" class="primayColor">{{ getShipVettingStatus(i.targetValue) }}</span>
                <span v-else class="primayColor">{{ i.targetValue ? i.targetValue : "Empty" }}</span>
              </p>
            </span>
          </div>
          <!-- <div v-else>
            <span v-for="(i, index) in item.subs" :key="index">
              <p v-if="i.fname == 'Block'" class="text-xs">
                <span style="color: #a1a6b5"> {{ i.fname }}</span> :
                <span v-if="i.oldValue && i.oldValue.length > 0"> Changed from </span>
                <span v-if="i.oldValue && i.oldValue.length > 0" class="primayColor">{{
                    i.oldValue == 1 ? 'Blocked' : 'Not Blocked'
                  }}</span>
                <span v-if="i.oldValue && i.oldValue.length > 0"> to </span>
                <span class="primayColor">{{ i.targetValue == 1 ? 'Blocked' : 'Not Blocked' }}</span>
              </p>
              <p v-else class="actionDev text-xs" v-show="i.fname">
                <span style="color: #a1a6b5"> {{ i.fname }}</span> :
                <span v-if="i.oldValue && i.oldValue.length > 0"> Changed from </span>
                <span v-if="i.oldValue && i.oldValue.length > 0"
                      class="primayColor">{{ i.oldValue ? i.oldValue : "Empty" }}</span>
                <span v-if="i.oldValue && i.oldValue.length > 0"> to </span>
                <span class="primayColor">{{ i.targetValue ? i.targetValue : "Empty" }}</span>
              </p>
            </span>
          </div> -->
        </el-timeline-item>
        <div class="py-5 text-center">
          <i v-if="loading" class="el-icon-refresh rotating"></i>
          <span v-else class="cursor-pointer text-xs primayColor" @click="loadMore">Load More</span>
        </div>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { getShipVettingHistoryTimeline } from "@/services";
import { getShipVettingStatus,getShipVettingLogType } from "@/utils/constants";
export default {
  name: "ShipVettingTimeline",
  props: {
    rid: {
      default: null,
    },
  },
  data() {
    return {
      activities: [],
      collection: [],
      Subs: [],
      timelineloading: true,
      loading: false,
      preScrollTop: 0,
      pageNum: 1,
      pageSize: 20,
      total: 0,
    };
  },
  created() {
    this.getlist();
  },
  watch: {
    rid: {
      immediate: true,
      handler() {
        this.getlist();
      },
    },
  },
  methods: {
    getShipVettingStatus,
    getShipVettingLogType,
    onScroll( event ) {    
      let scroll = event.target.scrollTop
      let height = event.target.scrollHeight
      
      if( this.isIncrease( scroll ) && this.hitTarget( scroll, height ) ) 
      {
        this.loadMore()
      }
    },
    isIncrease( scroll ) {
      let increasing = scroll > this.preScrollTop
      this.preScrollTop = scroll
      return increasing
    },
    hitTarget( scroll, height ) {
      let target = height * 0.75
      return scroll > target
    },
    loadMore() {
      this.getlist()
    },
    getlist() {
      if (this.rid) {
        if( ! this.loading ) {
          this.loading = true
          getShipVettingHistoryTimeline(this.rid, this.pageNum, this.pageSize).then((res) => {
            if (res.code == 200) {
              this.timelineloading = false;
              // this.activities = res.rows 
              if( res.rows && res.rows.length ) {
                this.total = res.total
                this.activities = this.activities.concat( this.collectData( res.rows ) )
                this.updatePageNum()
              }
            }
            this.loading = false
          });
        }
      } else {
        this.timelineloading = false;
      }
    },
    collectData( rows ) {
      let data = []
      rows.map(item => {
        if( ! this.collection.includes(item.id) ) {
          this.collection.push( item.id )
          data.push( item )
        }
      })
      return data
    },
    updatePageNum() {
      if( this.total >= ( this.pageNum * this.pageSize ) ) {
        this.pageNum++
      } 
    },
  },
};
</script>

<style lang="scss" scoped>
.timeLine {
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px;
  // height: 452px;
  // height: 750px;


  .head {
    font-weight: 750;
    // height: 45px;
    padding: 15px 22px 14px 22px;
    border-bottom: 2px solid #d7d9e1;
    color: #909399;
    font-size: 12px;
  }

  .content {
    padding: 40px 20px;
    // height: calc(432px - 56.5px);
    height: calc(100vh - 200px);
    max-width: 400px;
    overflow-y: scroll; 
  }

  .text {
    color: red;
  }
}

.lastItem ::v-deep .el-timeline-item__tail {
  display: none;
}

.loading .content {
  overflow: hidden;
}

::v-deep .el-timeline-item__node {
  background: $primaryColor;
}

::v-deep .el-timeline-item__tail {
  border-color: $primaryColor;
}

::v-deep .el-timeline-item__content {
  font-size: 14px;

  color: #82889c;
}

.timestamp {
  font-size: 12px;
  color: #82889c;
}

::v-deep .el-divider--horizontal {
  margin: 8px 0;
}

::v-deep .el-divider--horizontal {
  width: 230px;
}

::v-deep .el-timeline {
  height: 315px;
}
</style>
