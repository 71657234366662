<template>
  <div class="echart" v-loading="loading">
    <div class="pieChart">
      <vue-echarts v-if="optionData" :option="options" style="height: 100%;" ref="chart" />
    </div>
  </div>
</template>
<script>
import { VueEcharts } from 'vue3-echarts'

export default {
  name: "PieChart",
  components: {
    VueEcharts,
  },
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    optionData: {
      type: Object
    },
  },
  async created() {
  },
  data() {
    return {
      loading: false,
      title: '',
      seriesData: [],
      optionData2:{},
    };
  },
  computed: {
    options() {
      return {
        color: this.optionData.color,
        title: {
          text: this.optionData.title,
          left: 'left',
          top:'5%',
          padding: [0,0,20,0],
          show: true,
          textStyle:{
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          containLabel: true,
        },
        legend: {
          orient: 'vertical',
          // right: 'right',
          left: '60%',
          top: 'center',
          icon: 'square',
          width: '100%',
          height: 'auto',
          align: 'left',
          itemWidth: 15,
          padding: 0,
          show: true,
          textStyle:{
            fontSize: 12,
          },
          data: this.optionData.data?.map((item) => item.name), 
          formatter:  (name) =>{
            const item = this.optionData.data.find((item) => item.name === name)
            if (item) {
              return `${item.value}MT\t\t${name}  `
            }
            return name
          }
        },
        series: [
          {
            name: this.optionData.title,
            type: 'pie',
            radius: '60%',
            // bottom: '1%',
            // top: '10%',
            center: ['35%', '50%'],
            data: this.optionData.data,
            label: {
              show: true,
              position: 'inside',
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    }
  }
}
</script>
<style scoped>
.echart {
  width: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}
.pieChart {
  /* height: 230px;  */
  /* width: 350px; */
  height: 300px; 
  width: 600px;
}
</style>