var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"mapView overflow-x-auto overflow-y-hidden",class:{'map-mobile-view':_vm.screenType.isMobileScreen},style:({width:_vm.screenType.isMobileScreen ? `${_vm.screenType.mainLayoutWidth *2 -30}px`:'100%',marginLeft:_vm.screenType.isMobileScreen ?`-${_vm.screenType.mainLayoutWidth/2}px`:0,marginTop:_vm.screenType.isMobileScreen ?'-150px':0})},[_c('canvas',{ref:"myCanvas"}),_c('div',{staticClass:"bg"}),_vm._l((_vm.jettyPositionStyle),function(item){return _c('div',{staticClass:"item",style:(item.cardPositon),on:{"click":function($event){return _vm.handleItemClick(item)}}},[(_vm.jettyData[item.id]?.[0] || _vm.jettyData[item.id]?.[1])?_c('div',[_c('div',[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.getDisplayTime(_vm.showTaget(_vm.jettyData[item.id])["berthAssignTiming"])))])]),_c('div',{staticClass:"name placeholder"},[_vm._v(_vm._s(_vm.showTaget(_vm.jettyData[item.id])["companyName"]))]),_c('div',{staticClass:"name placeholder"},[_vm._v(_vm._s(_vm.showTaget(_vm.jettyData[item.id])["vesselName"]))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.showTaget(_vm.jettyData[item.id])["status"]))])]):_c('div',{staticClass:"notData"},[_vm._v("Not Assigned")])])}),_vm._l((_vm.jettyPositionStyle),function(item){return _c('div',{staticClass:"number",style:({
      ...item.numerPosition,
      background:
        _vm.jettyData[item.id]?.[0] || _vm.jettyData[item.id]?.[1]
          ? '#008CDB'
          : '#B8BCCA',
    })},[_vm._v(" "+_vm._s(item.num)+" ")])}),_vm._l((_vm.jettyPositionStyle),function(item){return _c('div',{staticClass:"line",style:(item.linePosition)})}),_vm._l((_vm.jettyPositionStyle),function(item){return (_vm.jettyData[item.id]?.[0] || _vm.jettyData[item.id]?.[1])?_c('img',{staticClass:"ship",style:(item.shipPosition),attrs:{"src":require('@/assets/map-view-ship.png'),"alt":"Ship Icon"}}):_vm._e()})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }