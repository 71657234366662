export const ROLE_MAP = {
  0: "Admin",
  1: "Terminal Logistics",
  2: "Terminal Control Room",
  3: "Terminal Team Leader",
  4: "Company Admin",
  5: "Customer User",
  6: "Surveyor Admin",
  7: "Surveyor",
  8: "Barge",
  9: "Barge Operator",
  10: "Terminal Pilot" ,
  11: "Terminal Loading Master",
};
export const  JETTY_LIST= {
  "3": [],
  "5": [], 
  "6": [],
  "7": [],
  "8": [],
  "9": [],
  "10": [],
  "11": [],
  "13": [],
  "15": [],
  "16": [],
  "17": [],
};
// for test
export const mockCompanyList =[
  {companyName:"0 Oil Marine Pte. Ltd.",num:2},
  {companyName:"0 Petro Private Limited",num:3},
  {companyName:"0 Champion Pte. Ltd.",num:4},
  {companyName:"1 Oil Marine Pte. Ltd.",num:2},
  {companyName:"1 Petro Private Limited",num:3},
  {companyName:"1 Champion Pte. Ltd.",num:4},
 ];

export const jettyHoseList = [
  { jettyNo: '3', jl: ["JL19C", "JL16"], hoses: ["LA35", "LA36"] },
  { jettyNo: '5', jl: ["JL14", "JL16"], hoses: ["BH52", "BH51"] },
  { jettyNo: '6', jl: ["JL14", "JL15", "JL39D", "JL39E"], hoses: ["BH61", "BH62", "WH61"] },
  { jettyNo: '7', jl: ["JL14", "JL15", "JL39E"], hoses: ["BH71", "BH72"] },
  { jettyNo: '8', jl: ["JL12", "JL13", "JL47", "JL46"], hoses: ["BH81", "BH82", "WH81", "WH82"] },
  { jettyNo: '9', jl: ["JL39D", "JL11", "JL13", "JL48"], hoses: ["BH91", "BH92", "WH91", "WH92"] },
  { jettyNo: '10', jl: ["JL12", "JL11"], hoses: ["BH101", "BH102"] },
  { jettyNo: '13', jl: ["JL19E"], hoses: ["L134"] },
  { jettyNo: '15', jl: ["JL68", "JL67", "JL66", "JL65", "JL39D", "JL21", "JL23"], hoses: ["BH151", "WH155", "WH154", "WH153", "WH152", "WH151"] },
  { jettyNo: '16', jl: ["JL39D", "JL68", "JL67", "JL66", "JL65", "JL21", "JL23"], hoses: ["BH161", "WH165", "WH164", "WH163", "WH162", "WH161"] },
  { jettyNo: '17', jl: ["JL21", "JL23", "JL39D"], hoses: ["BH171", "WH171"] }
];


export const typeOfSampleOptions = [
  { key: "Running", label: "Running" },
  { key: "Composite", label: "Composite" },
  { key: "Line Drip", label: "Line Drip" },
];

export const shipVettingRequestStatus = {
  0: "",
  1: "Pending Logistics",
  2: "Pending Inspector",
  3: "Inspector Responded",
  4: "Anchorage Inspection",
  5: "Rejected",
  6: "Accepted",
  7: "Pending Observation",
  8: "More Information Required",
  9: "Expired",
};
export function getShipVettingStatus (status){
  return shipVettingRequestStatus[status] || "";
}

export const shipVettingRequestLogType = {
  0: "",
  1: "Pending Logistics",
  2: "Pending Inspector",
  3: "Inspector Responded",
  4: "Anchorage Inspection",
  5: "Rejected",
  6: "Accepted",
  7: "Pending Observation",
  8: "More Information Required",
  9: "Customer Uploaded Document",
  10: "Info Modified",
  11: "Expired"
};
export function getShipVettingLogType (status){
  return shipVettingRequestLogType[status] || "";
}