<template>
  <div class="ship-vetting-container">
    <transition name="fade-transform" mode="out-in">
      <div v-if="pageStatus == 0">
        <ShipVettingList class=" barging-container"></ShipVettingList>
      </div>
      <div v-if="pageStatus == 1 || pageStatus == 2">
        <ShipVettingDetail class="top2"></ShipVettingDetail>
      </div>
  </transition>
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
import ShipVettingDetail from './component/detail.vue'
import ShipVettingList from './component/list.vue'
export default {
  name: "ShipVetting",
  components: { tabs, PdfView, ShipVettingList, ShipVettingDetail },
  watch: {
    // '$route' (to, from) {
    //     this.$router.go(0);
    // },
    "$route.query": {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (this.$route.query.pageStatus) {
          this.pageStatus = this.$route.query.pageStatus
        }else{
          this.pageStatus= 0
        }
      },
    },
    locale() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    pageStatus(){
    }
  },
  data() {
    return {
      pageStatus: 0,
      upIndex: 0,
    };
  },
  computed: {
    ...mapState(["locale","userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.orderListRef) {
      this.$refs.orderListRef.handleStopRefresh()
    }
    next()
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.top {
  // height: 100vh;
  // position: relative;
  // width: 100%;
  height: calc(100vh - 90px);
  // overflow: hidden;
  // box-sizing: border-box;
}
</style>
