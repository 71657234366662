<template>
  <div class="">
    <div class="flex flex-row " v-bind:class="{'flex-col':isMobileScreen}">
      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
        <div class="w-full Comtable">
          <el-table 
            key="historyTable" 
            :data="historyListData"
            :header-cell-style="{height: '45px',background: '#fff','border-bottom': '2px solid #D6D9E1',}"
            table-layout="auto"
            height="100%" 
            @row-click="handleTableRowClick"
            highlight-current-row
          >
            <el-table-column align="left" prop="createBy" label="Request Created By" min-width="150"></el-table-column>
            <el-table-column align="left" prop="createTime" label="Request Created On" min-width="200"></el-table-column>
            <el-table-column align="left" prop="shipStatus" label="Status" min-width="150" >
              <template slot-scope="scope">
                <div :style="scope.row.shipStatus === 6? { color: 'green' } : {}">
                  {{ getShipVettingStatus(scope.row.shipStatus) }}
                </div>
              </template>
              
            </el-table-column>
          </el-table>
        </div>
        <div class="barging-pagination">
          <pagination
            :total = "total"
            :page.sync="pageParams.pageNum"
            :limit.sync="pageParams.pageSize"
            :pageSizes="[10, 30, 50, 100]"
            @pagination="getList"
          ></pagination>
        </div>
      </div>
      <div class="timeline-container">
        <ShipVettingTimeline v-if="rid" :key="rid" :rid="rid"></ShipVettingTimeline>
      </div>
    </div>
  </div> 
</template>
  
<script>
import { getShipVettingHistory } from "@/services";
import { mapState } from "vuex";
import ShipVettingTimeline from './timeline.vue'
import { getShipVettingStatus } from "@/utils/constants";
export default {
  name: "ShipVettingHistory",
  components: { ShipVettingTimeline },
  props: {
    imo: {
      default: null,
    },
    shipName: {
      default: null,
    },
  },
  watch:{
    imo(){
      this.getList();
    },
    // immediate: true,
  },
  data() {
    return {
      historyListData: [],
      rid: "",
      listData: [],
      pageParams: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    // const { id, status } = this.$route.query;
    // console.log("ID:", id, "Status:", status);
  },
  methods: {
    getShipVettingStatus,
    getList() {
      let data = {
        ...this.pageParams,
        imo: this.imo
      };
      
      getShipVettingHistory(data).then((res) => {
        if(res.code == 200){
          this.historyListData = res.rows;
          this.total = res.total
        }
      });

      // if(this.historyListData?.length > 0){
      //   this.rid = row.id
      // }
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleTableRowClick(row) {
      this.rid = row.id
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table .cell {
  word-break:normal;
}
.Comtable {
  height: calc(100vh - 200px);
}
.timeline-container{
  border-left: 2px solid #d7d9e1;
  max-width: 400px;
}
::v-deep .pagination-container {
// background-color: white !important;
}
</style>
