import { mapState } from "vuex";

export default {
  name: "UserInfo",
  data() {
    return {
    }
  },
  mounted() {
    //console.log('mounted', this.userInfo)
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    getUserType() {
      return this.userInfo.userType
    },
    getRole() {
      return this.userInfo.role
    },
    isTerminal() {
      return this.getUserType() === 1
    },
    isTerminalLogistic() {
      return this.isTerminal() && this.getRole() === 1
    },
    isTerminalControlRoom() {
      return this.isTerminal() && this.getRole() === 2
    },
    isTerminalPilot() {
      return this.isTerminal() && this.getRole() === 10
    },
    isTerminalLoadingMaster() {
      return this.isTerminal() && this.getRole() === 11
    },
    isTerminalHeadPilot() {
      return this.isTerminal() && this.getRole() === 12
    },
    isBargeUser() {
      return this.getRole() === 8
    },
  }
}